import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Table, Button, Input, Modal } from 'antd';
import {
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { isEmpty } from 'lodash';
// import { CSVLink } from 'react-csv';
import { withRouter } from 'react-router-dom';
import MarketingApi from 'api/marketing';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray } from 'utils';

import { complainAfterSalesColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;

export function ManageKeluhanAfterSales({ history }) {
  const [code, setCode] = useState();
  const [name, setName] = useState();
  const [category, setCategory] = useState();

  const [listComplain, setListComplain] = useState([]);
  const [listFilteredComplain, setListFilteredComplain] = useState([]);
  const [listCategoryOptions, setListCategoryOptions] = useState([]);

  const [idModal, setIdModal] = useState();
  const [typeModal, setTypeModal] = useState();

  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);

  const [loadingPage, setLoadingPage] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  async function getCategoryList() {
    try {
      setLoadingPage(true);

      const response = await MarketingApi.getCategoryList();

      let categoryList = setToArray(response.data);

      const categoryListOpts = categoryList.map((value) => ({
        label: value.name,
        value: value.id,
      }));

      setListCategoryOptions(categoryListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getComplainList() {
    try {
      setLoadingPage(true);

      const response = await MarketingApi.getComplainList();

      let complainList = setToArray(response.data);

      setListComplain(complainList);
      setListFilteredComplain(complainList);
    } finally {
      setLoadingPage(false);
    }
  }

  async function submitComplain() {
    const payload = {
      name: name,
      afterSalesCategoryId: category,
    };

    const response = await MarketingApi.submitComplain(payload);

    if (response.data.resultCode === '00') {
      getComplainList();

      setName();
      setCategory();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function editComplain() {
    const payload = {
      name: name,
      code: code,
      category: category,
    };

    const response = await MarketingApi.editComplain(payload, idModal);

    if (response.data.resultCode === '00') {
      getComplainList();

      setIdModal();
      setCode();
      setName();
      setCategory();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  async function deleteComplain() {
    const response = await MarketingApi.deleteComplain(idModal);

    if (response.data.resultCode === '00') {
      getComplainList();

      setIdModal();

      setIsConfirmPopupOpen(false);
      setIsSuccessPopupOpen(true);
    }
  }

  const handleCodeChange = (event) => {
    setCode(event.target.value);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleCategoryChange = (value) => {
    setCategory(value);
  };

  const handleEditComplainClick = (id, value) => {
    handleFlow('edit', 'openPopup', id, value);
  };

  const handleDeleteComplainClick = (id) => {
    setIdModal(id);

    handleFlow('delete', 'openConfirm', id);
  };

  const handleFlow = (type, flow, id, value) => {
    if (typeModal !== type) setTypeModal(type);

    if (type === 'new') {
      if (flow === 'openPopup') {
        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setName();
        setCode();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        submitComplain();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setName();
        setCode();
        setCategory();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'edit') {
      if (flow === 'openPopup') {
        const { name, code, category } = value;

        setIdModal(id);
        setName(name);
        setCode(code);
        setCategory(category);

        setIsEditPopupOpen(true);
      } else if (flow === 'closePopup') {
        setIdModal();
        setName();
        setCode();
        setCategory();

        setIsEditPopupOpen(false);
      } else if (flow === 'openConfirm') {
        setIsEditPopupOpen(false);
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        editComplain();
      } else if (flow === 'closeSuccess') {
        setIdModal();
        setName();
        setCode();
        setCategory();

        setIsSuccessPopupOpen(false);
      }
    } else if (type === 'delete') {
      if (flow === 'openConfirm') {
        setIsConfirmPopupOpen(true);
      } else if (flow === 'closeConfirm') {
        setIsConfirmPopupOpen(false);
      } else if (flow === 'submit') {
        deleteComplain();
      } else if (flow === 'closeSuccess') {
        setIsSuccessPopupOpen(false);
      }
    }
  };

  const onTableChange = (pagination) => {
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listComplain.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredComplain(filteredData);
  };

  const useInitData = () => {
    getCategoryList();
    getComplainList();
  };

  const useEvaluateSubmitButton = () => {
    if (!!name && !!category) {
      setIsSubmitButtonEnabled(true);
    } else {
      setIsSubmitButtonEnabled(false);
    }
  };

  useEffect(useInitData, []);

  useEffect(useEvaluateSubmitButton, [name, category]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Manage Keluhan After Sales
              </strong>
            </Col>
          </Row>
        </div>
        <div className="panel">
          <Row>
            <Col span={12}>
              <div className="mb-15">
                <Button
                  style={{
                    color: '#FFF',
                    backgroundColor: '#32c5d2',
                    borderColor: '#32c5d2',
                  }}
                  onClick={() => handleFlow('new', 'openPopup')}>
                  <strong>TAMBAH KELUHAN AFTER SALES</strong>
                </Button>
              </div>
            </Col>
            <Col span={12} type="flex" align="end">
              <div className="mb-15">
                <Button onClick={() => {}}>PRINT</Button>
                <Button onClick={() => {}}>COPY</Button>
                <Button onClick={() => {}}>PDF</Button>
                <Button onClick={() => {}}>EXCEL</Button>
                <Button onClick={() => {}}>CSV</Button>
                <Button onClick={getComplainList}>RELOAD</Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={2} type="flex" align="start">
              <></>
            </Col>
            <Col span={5} offset={17} type="flex" align="end">
              <div className="mb-15">
                <Input
                  className="skybase-dashboard__search-input"
                  placeholder="Search"
                  onChange={onSearchChange}
                  value={search}
                />
              </div>
            </Col>
          </Row>
          <Table
            dataSource={listFilteredComplain}
            rowKey="id"
            scroll={{ y: 500 }}
            onChange={onTableChange}
            pagination={pagination}
            columns={complainAfterSalesColumns(
              handleEditComplainClick,
              handleDeleteComplainClick
            )}
            className="skybase-dashboard__active-order-table"
          />
        </div>
      </Content>

      <Modal
        visible={isEditPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="danger"
            onClick={() => handleFlow(typeModal, 'closePopup')}>
            Kembali
          </Button>,
          <Button
            type="primary"
            onClick={() => handleFlow(typeModal, 'openConfirm')}
            disabled={!isSubmitButtonEnabled}>
            Simpan
          </Button>,
        ]}>
        <Row type="flex" align="middle">
          <Col span={6} className="mb-5">
            <div>Nama</div>
          </Col>
          <Col span={18}>
            <div className="skybase-dashboard__search mb-5">
              <Input
                placeholder="Nama"
                onChange={handleNameChange}
                value={name}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {typeModal === 'edit' ? (
              <Row type="flex" align="middle">
                <Col span={6} className="mb-5">
                  <div>Kode</div>
                </Col>
                <Col span={18}>
                  <div className="skybase-dashboard__search mb-5">
                    <Input
                      placeholder="Kode"
                      onChange={handleCodeChange}
                      value={code}
                    />
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row type="flex" align="middle">
              <Col span={6} className="mb-5">
                <div>Kategori After Sales</div>
              </Col>
              <Col span={18}>
                <div className="skybase-dashboard__search mb-5">
                  <SelectDropdown
                    options={listCategoryOptions}
                    placeHolder="Kategori After Sales"
                    onChange={handleCategoryChange}
                    value={category}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modal
        visible={isConfirmPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="default"
            onClick={() => handleFlow(typeModal, 'closeConfirm')}>
            Tidak
          </Button>,
          <Button type="danger" onClick={() => handleFlow(typeModal, 'submit')}>
            Ya
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <ExclamationCircleTwoTone
              twoToneColor="#f8bb86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        {typeModal === 'delete' ? (
          <>
            <Row>
              <div className="mb-10">
                <strong>Anda sudah yakin melakukan penghapusan data?</strong>
              </div>
            </Row>
            <Row>
              <div>Data yang sudah dihapus tidak dapat dikembalikan</div>
            </Row>
          </>
        ) : (
          <Row>
            <div>
              <strong>Apakah data sudah sesuai?</strong>
            </div>
          </Row>
        )}
      </Modal>

      <Modal
        visible={isSuccessPopupOpen}
        width={'35%'}
        closable={false}
        footer={[
          <Button
            type="primary"
            onClick={() => handleFlow(typeModal, 'closeSuccess')}>
            OK
          </Button>,
        ]}>
        <Row>
          <div className="mt-10 mb-10">
            <CheckCircleTwoTone
              twoToneColor="#a5dc86"
              style={{ fontSize: 50 }}
            />
          </div>
        </Row>
        <Row>
          <div className="mb-10">
            <strong>Success</strong>
          </div>
        </Row>
        <Row>
          <div>
            Data berhasil {typeModal === 'delete' ? 'dihapus' : 'disimpan'}
          </div>
        </Row>
      </Modal>
    </Layout>
  );
}

export default withRouter(ManageKeluhanAfterSales);
