import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Layout,
  Table,
  Button,
  Input,
  DatePicker,
  Modal,
  Collapse,
  Dropdown,
  Menu,
} from 'antd';
import { DownloadOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import ReportApi from 'api/report';
// import TreatmentApi from 'api/treatment';
import UserApi from 'api/user';
import ClinicApi from 'api/clinic';
import TreatmentApi from 'api/treatment';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import MultiselectDropdown from 'components/multiselect-dropdown';
import { setToArray, currencyFormatter } from 'utils';
import XLSX from 'xlsx';

import { discountSummaryColumns } from '../../kasir/helper';
import { doctorTreatmentReportColumns } from '../helper';
import '../styles/index.scss';
import AuthenticationApi from 'api/authentication';

const { Content } = Layout;
const { Panel } = Collapse;
const { Search } = Input;

export function DoctorTreatmentReport({ history }) {
  const objectSummaryReportDetail = [
    {
      key: 1,
      column1: 'Sub Total',
      column2: 0,
    },
  ];

  const listDateRange = [
    {
      label: 'Last 7 Days',
      value: 'SEVEN',
    },
    {
      label: 'Last 30 Days',
      value: 'THIRTY',
    },
    {
      label: 'Custom Range',
      value: 'CUSTOM',
    },
  ];

  const listPaymentStatus = [
    {
      label: 'Lunas',
      value : 'PAID',
    },
    {
      label: 'Belum Lunas',
      value: 'CONFIRMED',
    },
    {
      label: 'Void',
      value: 'VOID'
    }
  ];

  var permissionDataList = [];

  const [service, setService] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rangeDate, setRangeDate] = useState();
  const [statusPayment, setStatusPayment] = useState();

  const [listClinic, setListClinic] = useState([]);
  const [listDoctor, setListDoctor] = useState();
  const [listServiceCategory, setListServiceCategory] = useState([]);
  const [listServiceCategoryOptions, setListServiceCategoryOptions] = useState([]);
  const [listClinicOptions, setListClinicOptions] = useState([]);
  const [listDoctorOptions, setListDoctorOptions] = useState([]);
  const [listServiceOptions, setListServiceOptions] = useState([]);

  const [listReportDetail, setListReportDetail] = useState([]);
  const [listFilteredReportDetail, setListFilteredReportDetail] = useState([]);
  const [listSummaryReportDetail, setListSummaryReportDetail] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
    total: 0,
  });

  async function getReportDetailList(limit, page, status) {
    try {
      setLoadingTable(true);
      const { data } = await AuthenticationApi.permissions();
      permissionDataList = [...data.result];

      let doctorIds = 0;
      if (permissionDataList.find((item) => item === 225)) {
        const response = await UserApi.getDoctorList();
        let doctorRole = process.env.REACT_APP_DOCTOR_ROLE_IDS.split(',');
        let role = localStorage.getItem('role').split(',');
        if (doctorRole.some((element) => {
          return role.includes(element);
        })) {
          let doctorList = Object.values(response.data.result);
  
        const doctorListOpts = doctorList.map((value) => ({
          label: value.name,
          value: value.id_user,
        }));
  
        if (status !== 'filter') {
          setListDoctor();
        }
        setListDoctorOptions(doctorListOpts);
        }
        setIsDisabled(false);
        doctorIds = status === 'reset' ? '' : listDoctor;
      } else {
        setIsDisabled(true);
        doctorIds = localStorage.getItem('id');
      }
      let serviceIds = status === 'reset' ? '' : service.join(',');
      let serviceCategoryIds = status === 'reset' ? '' : listServiceCategory.join(',');
      let clinicIds = status === 'reset' ? '' : listClinic.map((item) => {
        return item;
      }).join(',');
      let paymentStatus = status === 'reset' ? '' : statusPayment;
      const today = moment();
      const thirtyDaysAgo = moment().subtract(30, 'd');
      const response = await ReportApi.getDoctorTreatmentReport(
        doctorIds ? doctorIds : '',
        service.length > 0 ? serviceIds : '',
        listServiceCategory.length > 0 ? serviceCategoryIds : '',
        listClinic.length > 0 ? clinicIds : '',
        statusPayment ? paymentStatus : '',
        moment(status !== 'filter' ? thirtyDaysAgo : startDate).format('YYYY-MM-DD'),
        moment(status !== 'filter' ? today : endDate).format('YYYY-MM-DD'),
        limit,
        page,
      )

      let reportDetailList = setToArray(response.data);
      const modifiedReportDetailList = reportDetailList.map(
        (item, index) => ({
          ...item,
          key: index + 1,
        })
      );

      setListReportDetail(modifiedReportDetailList);
      setListFilteredReportDetail(modifiedReportDetailList);

      const newReportDetailSummary = [...objectSummaryReportDetail];

      newReportDetailSummary[0].column2 = currencyFormatter(
        response.data.data.subTotal
      );
      setListSummaryReportDetail(objectSummaryReportDetail);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    } finally {
      setLoadingTable(false);
    }
  }

  async function getDoctorList() {
    try {
      setLoadingPage(true);

      const response = await UserApi.getDoctorList();

      let doctorRole = process.env.REACT_APP_DOCTOR_ROLE_IDS.split(',');
      let role = localStorage.getItem('role').split(',');
      if (doctorRole.some(element => {
        return role.includes(element);
      })) {
        let userId = localStorage.getItem('id');
        let username = localStorage.getItem('name');
        setListDoctor(username);
        const doctorListOpts = [{
          label: username,
          value: userId
        }];
        setListDoctorOptions(doctorListOpts);
      } else {
        let doctorList = Object.values(response.data.result);
  
        const doctorListOpts = doctorList.map((value) => ({
          label: value.name,
          value: value.id_user,
        }));
  
        setListDoctorOptions(doctorListOpts);
      }
    } finally {
      setLoadingPage(false);
    }
  }

  async function getClinicList() {
    try {
      setLoadingPage(true);

      const response = await ClinicApi.listClinic();

      let clinicList = setToArray(response.data);

      const clinicListOpts = clinicList.map((value) => ({
        label: value.clinicName,
        value: value.id,
      }));

      setListClinicOptions(clinicListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getServiceList() {
    try {
      setLoadingPage(true);

      const response = await TreatmentApi.listTreatmentOptions();

      let serviceList = response.data.data;

      const serviceListOpts = serviceList.map((value) => ({
        label: value.name,
        value: value.id,
      }));

      setListServiceOptions(serviceListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function getServiceCategoryList() {
    try {
      setLoadingPage(true);

      const response = await TreatmentApi.listTreatmentCategoryOptions();

      let serviceCategoryList = setToArray(response.data);

      const serviceCategoryListOpts = serviceCategoryList.map((value) => ({
        label: value.name,
        value: value.id,
      }));

      setListServiceCategoryOptions(serviceCategoryListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  async function generateExcelReport() {
    try {
      let role = localStorage.getItem('role');
      const { data } = await AuthenticationApi.permissions();
      permissionDataList = [...data.result];

      let doctorIds = 0;
      if (permissionDataList.find((item) => item === 225)) {
        doctorIds = listDoctor;
      } else {
        doctorIds =   localStorage.getItem('id');
      }
      let serviceIds = service.join(',');
      let serviceCategoryIds = listServiceCategory.join(',');
      let clinicIds = listClinic.map((item) => {
        return item;
      }).join(',');
      let paymentStatus = statusPayment;
      const response = await ReportApi.getDoctorTreatmentReport(
        doctorIds ? doctorIds : '',
        service.length > 0 ? serviceIds : '',
        listServiceCategory.length > 0 ? serviceCategoryIds : '',
        listClinic.length > 0 ? clinicIds : '',
        statusPayment ? paymentStatus : '',
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        // limit,
        // page,
      )
      let reportDetailList = setToArray(response.data);

      let arrayExcel = [
        [
          'No',
          'Tanggal',
          'Jam',
          'Doctor Name',
          'Service Category',
          'Service',
          'Price',
          'Qty',
          'Total Price',
          'Payment Status',
          'Void Reason',
          'Clinic',
        ],
      ];

      reportDetailList.forEach((item, index) => {
        arrayExcel.push([
          index + 1, //No
          moment(item.timestamp).format('DD-MM-YYYY'), // Tanggal
          moment(item.timestamp).format('HH:mm'), // Jam
          item.doctorName ? item.doctorName : '-', // Nama Dokter
          item.serviceCategoryName ? item.serviceCategoryName : '-', // Service Category
          item.serviceName ? item.serviceName : '-', // Service
          item.price ? item.price : 0, // Price
          item.qty ? item.qty : 0, // Jumlah
          item.totalPrice ? item.totalPrice : '-', // Total Price
          item.status === 'CONFIRMED' ? 'UNPAID' : item.status, // Payment Status
          item.voidReason ? item.voidReason : '-',
          item.clinicName ? item.clinicName : 0, // Clinic
        ]);
      });

      arrayExcel.push(
        [
          '', //No
          '', // Tanggal
          '', // Jam
          '', // Nama dokter
          '', // Service Category
          '', // Service
          '', // Price
          'Sub Total', // Qty
          response.data.data.subTotal, // Total Price
          '', // Payment Status
          '', // Clinic
        ],
      );

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'Data Laporan Treatment Dokter');

      const fileName = `Data_Laporan_Treatment_Dokter-${moment().format(
        'YYYY-MM-DD'
      )}.csv`;

      /* Trigger Download with `writeFile` */
      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    }
  }

  const handleServiceCategoryChange = (value) => {
    setListServiceCategory(value);
  };

  const handlePaymentStatusChange = (value) => {
    setStatusPayment(value);
  };

  const handleDoctorChange = (value) => {
    setListDoctor(value);
  }

  const handleClinicChange = (value) => {
    setListClinic(value);
  }

  const handleServiceChange = (value) => {
    setService(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const handleRangeDateChange = (value) => {
    const today = moment();

    if (value === 'SEVEN') {
      const sevenDaysAgo = moment().subtract(7, 'd');

      setStartDate(sevenDaysAgo);
      setEndDate(today);
    } else if (value === 'THIRTY') {
      const thirtyDaysAgo = moment().subtract(30, 'd');

      setStartDate(thirtyDaysAgo);
      setEndDate(today);
    } else {
      setStartDate();
      setEndDate();
    }

    setRangeDate(value);
  };

  const onTableChange = (pagination) => {
    getReportDetailList(pagination.pageSize, pagination.current, 'filter');
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listReportDetail.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredReportDetail(filteredData);
  };

  const resetAllFields = async() => {
    handleRangeDateChange('THIRTY');
    setListClinic([]);
    setStatusPayment();
    setListServiceCategory([]);
    setService([]);
    setListDoctor();
    getReportDetailList(10,1,'reset');
  };

  const useInitData = async() => {
    handleRangeDateChange('THIRTY');
    getClinicList();
    getDoctorList();
    getServiceList();
    getServiceCategoryList();
    getReportDetailList(pagination.pageSize, pagination.current, 'new');
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row gutter={24}>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Doctor Treatment Report
              </strong>
            </Col>
          </Row>
        </div>
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="mb-15">
          <Panel
            header={
              <strong className="text-md skybase-dashboard__title">
                Filter
              </strong>
            }
            key="1">
            <div>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Date Range
                  <SelectDropdown
                    options={listDateRange}
                    placeHolder="Select Range Date"
                    onChange={handleRangeDateChange}
                    value={rangeDate}
                    allowClear
                  />
                </Col>
                <Col span={8}>
                  Start Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleStartDateChange}
                        value={startDate}
                        placeholder="Start Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  End Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        onChange={handleEndDateChange}
                        value={endDate}
                        placeholder="End Date"
                        disabled={rangeDate !== 'CUSTOM'}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Doctor
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <SelectDropdown
                        disabled={isDisabled}
                        options={listDoctorOptions}
                        placeHolder="Select Doctor"
                        onChange={handleDoctorChange}
                        value={listDoctor}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  Service Categories
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <MultiselectDropdown
                        options={listServiceCategoryOptions}
                        placeHolder="Select Service Categories"
                        showArrow
                        onChange={handleServiceCategoryChange}
                        selectValue={listServiceCategory}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  Services
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <MultiselectDropdown
                        options={listServiceOptions}
                        placeHolder="Select Services"
                        showArrow
                        onChange={handleServiceChange}
                        selectValue={service}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16,8]}>
                <Col span={8}>
                  Clinics
                  <div className='mb-15'>
                    <div className='skybase-dashboard__search'>
                      <MultiselectDropdown
                        options={listClinicOptions}
                        placeHolder='Select Clinics'
                        showArrow
                        onChange={handleClinicChange}
                        selectValue={listClinic}
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  Payment Status
                  <div className='mb-15'>
                    <div className='skybase-dashboard__search'>
                      <SelectDropdown
                        options={listPaymentStatus}
                        onChange={handlePaymentStatusChange}
                        value={statusPayment}
                        placeHolder='Select Payment Status'
                        allowClear
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mt-20 mb-10">
                <Col span={24} type="flex" align="end">
                  <Row gutter={[16, 8]}>
                    <Button onClick={resetAllFields}>Reset</Button>
                    <Button
                      type="primary"
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => getReportDetailList(10, 1, 'filter')}>
                      Filter
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
        <div className="panel">
          <Row>
            <Col span={24}>
              <div className="mb-15">
                <strong className="text-md skybase-dashboard__title">
                  Doctor Treatment Report
                </strong>
              </div>
            </Col>
          </Row>
          {loadingTable ? (
            <PageSpinner />
          ) : (
            <>
              <Row>
                <Col span={2} type="flex" align="start">
                  <div className="mb-15">
                    <Dropdown
                      trigger={['click']}
                      overlay={() => (
                        <>
                          <Menu>
                            <Menu.Item>
                              <Button type="link" onClick={generateExcelReport}>
                                CSV Doctor Treatment Report
                              </Button>
                            </Menu.Item>
                            {/* <Menu.Item>
                              <Button
                                type="link"
                                onClick={() => generateExcelJurnalIdReport(2)}>
                                Excel Sales Invoice - Jurnal.ID
                              </Button>
                            </Menu.Item>
                            <Menu.Item>
                              <Button
                                type="link"
                                onClick={() => generateExcelJurnalIdReport(3)}>
                                Excel Insurance Sales Invoice - Jurnal.ID
                              </Button>
                            </Menu.Item> */}
                          </Menu>
                        </>
                      )}
                      placement="bottomLeft"
                      arrow>
                      <Button>
                        <DownloadOutlined />
                        Export File <DownOutlined />
                      </Button>
                    </Dropdown>
                  </div>
                </Col>
                <Col span={5} offset={17} type="flex" align="end">
                  <div className="mb-15">
                    <Search
                      placeholder="Search"
                      onChange={onSearchChange}
                      value={search}
                    />
                  </div>
                </Col>
              </Row>
              <Table
                dataSource={listFilteredReportDetail}
                rowKey="key"
                columns={doctorTreatmentReportColumns(pagination.current)}
                onChange={onTableChange}
                className="skybase-dashboard__active-order-table"
                footer={() => (
                  <Table
                    dataSource={listSummaryReportDetail}
                    rowKey="key"
                    columns={discountSummaryColumns}
                    className="skybase-dashboard__active-order-table"
                    showHeader={false}
                    pagination={false}
                    size="small"
                  />
                )}
                pagination={pagination}
                size="small"
                scroll={{ x: 2000, y: 500 }}
              />
            </>
          )}
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(DoctorTreatmentReport);
