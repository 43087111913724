import React, { useEffect, useState } from 'react';
import {
  Col,
  Card,
  Row,
  Layout,
  Table,
  Button,
  Input,
  DatePicker,
  Modal,
  Collapse,
  Dropdown,
  Icon,
  Menu,
} from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import ReportApi from 'api/report';
import UserApi from 'api/user';
import { PageSpinner } from 'components/page-spinner';
import SelectDropdown from 'components/select-dropdown';
import { setToArray, currencyFormatter } from 'utils';
import XLSX from 'xlsx';

import { cashierReceiptVoidColumns, discountSummaryColumns } from '../helper/';
import '../styles/index.scss';

const { Content } = Layout;
const { Panel } = Collapse;
const { Search } = Input;

export function ManageBatalKasirPasien({ history }) {
  const objectReceiptDetail = [
    {
      key: 1,
      column1: 'Total Nilai Yang Di Tagihkan',
      column2: 0,
    },
    {
      key: 2,
      column1: 'Total Diskon',
      column2: 0,
    },
    {
      key: 3,
      column1: 'Total PPN',
      column2: 0,
    },
    {
      key: 4,
      column1: 'Total Nilai Yang Di Bayarkan',
      column2: 0,
    },
    {
      key: 5,
      column1: 'Total Kembalian',
      column2: 0,
    },
  ];

  const listDateRange = [
    {
      label: 'Last 7 Days',
      value: 'SEVEN',
    },
    {
      label: 'Last 30 days',
      value: 'THIRTY',
    },
    {
      label: 'Custom Range',
      value: 'CUSTOM',
    },
  ];

  const [cashier, setCashier] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [rangeDate, setRangeDate] = useState();
  const [startDateVoid, setStartDateVoid] = useState();
  const [endDateVoid, setEndDateVoid] = useState();
  const [rangeDateVoid, setRangeDateVoid] = useState();

  const [listCashierOptions, setListCashierOptions] = useState([]);

  const [listReceipt, setListReceipt] = useState([]);
  const [listFilteredReceipt, setListFilteredReceipt] = useState([]);
  const [listSummaryReceipt, setListSummaryReceipt] = useState([]);

  const [loadingPage, setLoadingPage] = useState(false);

  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    pageSizeOptions: [5, 10, 15, 20],
    showSizeChanger: true,
  });

  async function getReceiptList(limit, page) {
    try {
      setLoadingPage(true);

      const response = await ReportApi.getCashierReceiptVoidList(
        cashier,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        startDateVoid ? moment(startDateVoid).format('YYYY-MM-DD') : '',
        endDateVoid ? moment(endDateVoid).format('YYYY-MM-DD') : '',
        limit,
        page
      );

      let receiptList = setToArray(response.data);

      const modifiedReceiptList = receiptList.map((item, index) => ({
        ...item,
        key: index + 1,
      }));

      setListReceipt(modifiedReceiptList);
      setListFilteredReceipt(modifiedReceiptList);

      const newReceiptSummary = [...objectReceiptDetail];

      newReceiptSummary[0].column2 = currencyFormatter(
        response.data.data.grandTotal ? response.data.data.grandTotal : 0
      );
      newReceiptSummary[1].column2 = currencyFormatter(
        response.data.data.totalDiscount ? response.data.data.totalDiscount : 0
      );
      newReceiptSummary[2].column2 = currencyFormatter(
        response.data.data.totalTax ? response.data.data.totalTax : 0
      );
      newReceiptSummary[3].column2 = currencyFormatter(
        response.data.data.amountPaidTotal
          ? response.data.data.amountPaidTotal
          : 0
      );
      newReceiptSummary[4].column2 = currencyFormatter(
        response.data.data.changeReturnTotal
          ? response.data.data.changeReturnTotal
          : 0
      );

      setListSummaryReceipt(newReceiptSummary);

      const paginationConfig = {
        ...pagination,
        current: response.data.data.page,
        pageSize: response.data.data.limit,
        total: response.data.data.totalData,
      };

      setPagination(paginationConfig);
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    } finally {
      setLoadingPage(false);
    }
  }

  async function getCashierList() {
    try {
      setLoadingPage(true);

      const response = await UserApi.getCashierList();

      let cashierList = Object.values(response.data.result);

      const cashierListOpts = cashierList.map((value) => ({
        label: value.name,
        value: value.id_user,
      }));

      setListCashierOptions(cashierListOpts);
    } finally {
      setLoadingPage(false);
    }
  }

  const generateExcelReport = async () => {
    try {
      const response = await ReportApi.getCashierReceiptVoidList(
        cashier,
        moment(startDate).format('YYYY-MM-DD'),
        moment(endDate).format('YYYY-MM-DD'),
        startDateVoid ? moment(startDateVoid).format('YYYY-MM-DD') : '',
        endDateVoid ? moment(endDateVoid).format('YYYY-MM-DD') : ''
      );

      let receiptDetailList = setToArray(response.data);

      let paymentDiscountSummary = 0;
      let subTotalSummary = 0;
      let grandTotalSummary = 0;
      let taxSummary = 0;

      receiptDetailList.forEach((item, index) => {
        grandTotalSummary += item.grandTotal;
        paymentDiscountSummary += item.discountTotal;
        taxSummary += item.tax;
        subTotalSummary += item.subTotal;
      });

      let arrayExcel = [
        [
          'No',
          'No RM - Nama Pasien',
          'No Kwitansi',
          'Transaction Date',
          'Treatment',
          'Qty',
          'Doctor Name',
          'CS Name',
          'Void & Retur Date',
          'Voided & Returned by',
          'Void & Retur Reason',
          'Nilai yang ditagihkan',
          'Diskon',
          'PPN',
          'Nilai yang dibayarkan',
          'Kembalian',
          'Tanggal Bayar',
          'Metode Pembayaran',
          'Nama Kasir',
        ],
      ];

      receiptDetailList.forEach((item, index) => {
        arrayExcel.push([
          index + 1, //No
          `${item.medicalRecordNumber} - ${item.patientName}`, // No RM - Nama pasien
          item.receiptNumber ? item.receiptNumber : '-', // No Kwitansi
          moment(item.paymentDate).format('DD MMM YYYY HH:mm'), // Transaction Date
          item.treatmentDescription ? item.treatmentDescription : '-', // Treatment
          item.quantity ? item.quantity : 0, // Qty
          item.doctorName ? item.doctorName : '-', // Doctor Name
          item.createdBy ? item.createdBy : '-', // CS Name
          moment(item.voidAt).format('DD MMM YYYY HH:mm'), // Void & Retur Date
          item.voidByName ? item.voidByName : '-', // Voided & Returned by
          item.voidReason ? item.voidReason : '-', // Void & Retur Reason
          item.subTotal ? item.subTotal : 0, // Nilai yang ditagihkan
          item.discountTotal ? item.discountTotal : 0, // Diskon
          item.tax ? item.tax : 0, // PPN
          item.grandTotal ? item.grandTotal : 0, // Nilai yang dibayarkan
          item.changeReturn ? item.changeReturn : 0, // Kembalian
          moment(item.paymentDate).format('DD MMM YYYY'), // Tanggal Bayar
          item.paymentMethod, // Metode Pembayaran
          item.cashierName ? item.cashierName : '-', //Nama kasir
        ]);
      });

      arrayExcel.push(
        [
          '', // 'No',
          '', // 'No RM - Nama Pasien',
          '', // 'No Kwitansi',
          '', // 'Transaction Date',
          '', // 'Treatment',
          '', // 'Qty',
          '', // 'Doctor Name',
          '', // 'CS Name',
          '', // 'Void & Retur Date',
          '', // 'Voided & Returned by',
          '', // 'Void & Retur Reason',
          '', // 'Nilai yang ditagihkan',
          '', // 'Diskon',
          'Total Nilai Yang Di Tagihkan', // 'PPN',
          response.data.data.grandTotal, // 'Nilai yang dibayarkan',
          '', // 'Kembalian',
          '', // 'Tanggal Bayar',
          '', // 'Metode Pembayaran',
          '', // 'Nama Kasir',
        ],
        [
          '', // 'No',
          '', // 'No RM - Nama Pasien',
          '', // 'No Kwitansi',
          '', // 'Transaction Date',
          '', // 'Treatment',
          '', // 'Qty',
          '', // 'Doctor Name',
          '', // 'CS Name',
          '', // 'Void & Retur Date',
          '', // 'Voided & Returned by',
          '', // 'Void & Retur Reason',
          '', // 'Nilai yang ditagihkan',
          '', // 'Diskon',
          'Total Diskon', // 'PPN',
          response.data.data.totalDiscount, // 'Nilai yang dibayarkan',
          '', // 'Kembalian',
          '', // 'Tanggal Bayar',
          '', // 'Metode Pembayaran',
          '', // 'Nama Kasir',
        ],
        [
          '', // 'No',
          '', // 'No RM - Nama Pasien',
          '', // 'No Kwitansi',
          '', // 'Transaction Date',
          '', // 'Treatment',
          '', // 'Qty',
          '', // 'Doctor Name',
          '', // 'CS Name',
          '', // 'Void & Retur Date',
          '', // 'Voided & Returned by',
          '', // 'Void & Retur Reason',
          '', // 'Nilai yang ditagihkan',
          '', // 'Diskon',
          'Total PPN', // 'PPN',
          response.data.data.totalTax, // 'Nilai yang dibayarkan',
          '', // 'Kembalian',
          '', // 'Tanggal Bayar',
          '', // 'Metode Pembayaran',
          '', // 'Nama Kasir',
        ],
        [
          '', // 'No',
          '', // 'No RM - Nama Pasien',
          '', // 'No Kwitansi',
          '', // 'Transaction Date',
          '', // 'Treatment',
          '', // 'Qty',
          '', // 'Doctor Name',
          '', // 'CS Name',
          '', // 'Void & Retur Date',
          '', // 'Voided & Returned by',
          '', // 'Void & Retur Reason',
          '', // 'Nilai yang ditagihkan',
          '', // 'Diskon',
          'Total Nilai Yang Di Bayarkan', // 'PPN',
          response.data.data.amountPaidTotal, // 'Nilai yang dibayarkan',
          '', // 'Kembalian',
          '', // 'Tanggal Bayar',
          '', // 'Metode Pembayaran',
          '', // 'Nama Kasir',
        ],
        [
          '', // 'No',
          '', // 'No RM - Nama Pasien',
          '', // 'No Kwitansi',
          '', // 'Transaction Date',
          '', // 'Treatment',
          '', // 'Qty',
          '', // 'Doctor Name',
          '', // 'CS Name',
          '', // 'Void & Retur Date',
          '', // 'Voided & Returned by',
          '', // 'Void & Retur Reason',
          '', // 'Nilai yang ditagihkan',
          '', // 'Diskon',
          'Total Kembalian', // 'PPN',
          response.data.data.changeReturnTotal, // 'Nilai yang dibayarkan',
          '', // 'Kembalian',
          '', // 'Tanggal Bayar',
          '', // 'Metode Pembayaran',
          '', // 'Nama Kasir',
        ]
      );

      var wb = XLSX.utils.book_new();
      var ws = XLSX.utils.aoa_to_sheet(arrayExcel);
      XLSX.utils.book_append_sheet(wb, ws, 'Data Laporan Batal Kasir Pasien');

      const fileName = `Data_Laporan_Batal_Kasir_Pasien-${moment().format(
        'YYYY-MM-DD'
      )}.xlsx`;

      XLSX.writeFile(wb, fileName, { compression: true });
    } catch (error) {
      Modal.warning({
        title: 'PERHATIAN',
        content: 'Terjadi error pada server. Silahkan coba lagi!',
      });
    }
  };
  const handleCashierChange = (value) => {
    setCashier(value);
  };

  const handleStartDateChange = (date, dateString) => {
    if (!date) setStartDate(moment());
    else setStartDate(date);
  };

  const handleEndDateChange = (date, dateString) => {
    if (!date) setEndDate(moment());
    else setEndDate(date);
  };

  const handleRangeDateChange = (value) => {
    const today = moment();

    if (value === 'SEVEN') {
      const sevenDaysAgo = moment().subtract(7, 'd');

      setStartDate(sevenDaysAgo);
      setEndDate(today);
    } else if (value === 'THIRTY') {
      const thirtyDaysAgo = moment().subtract(30, 'd');

      setStartDate(thirtyDaysAgo);
      setEndDate(today);
    } else {
      setStartDate(today);
      setEndDate(today);
    }

    setRangeDate(value);
  };

  const handleStartDateChangeVoid = (date, dateString) => {
    if (!date) setStartDateVoid(moment());
    else setStartDateVoid(date);
  };

  const handleEndDateChangeVoid = (date, dateString) => {
    if (!date) setEndDateVoid(moment());
    else setEndDateVoid(date);
  };

  const handleRangeDateChangeVoid = (value) => {
    const today = moment();

    if (value === 'SEVEN') {
      const sevenDaysAgo = moment().subtract(7, 'd');

      setStartDateVoid(sevenDaysAgo);
      setEndDateVoid(today);
    } else if (value === 'THIRTY') {
      const thirtyDaysAgo = moment().subtract(30, 'd');

      setStartDateVoid(thirtyDaysAgo);
      setEndDateVoid(today);
    } else {
      setStartDateVoid();
      setEndDateVoid();
    }

    setRangeDateVoid(value);
  };

  const handlePrintReceiptClick = (appointmentId, receiptNumber) => {
    history.push(`/kasir/kwitansi/${appointmentId}_${receiptNumber}`);
  };

  const onTableChange = (pagination) => {
    getReceiptList(pagination.pageSize, pagination.current);
    setPagination(pagination);
  };

  //search
  const onSearchChange = (e) => {
    const convertValue = e.target.value.toLowerCase();
    const labelValue = e.target.value;
    setSearch(labelValue);

    const filteredData = listReceipt.filter((item) => {
      return Object.keys(item).some(
        (key) =>
          !isEmpty(item[key]) &&
          item[key].toString().toLowerCase().search(convertValue) !== -1
      );
    });

    setListFilteredReceipt(filteredData);
  };

  const resetAllFields = () => {
    setRangeDate();
    setStartDate();
    setEndDate();
    setRangeDateVoid();
    setStartDateVoid();
    setEndDateVoid();
    setCashier();
    getReceiptList(10, 1);
  };

  const useInitData = () => {
    getReceiptList(10, 1);
    getCashierList();
    const today = moment();
    setStartDate(today);
    setEndDate(today);
  };

  useEffect(useInitData, []);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <Layout className="order-list-page">
      <Content>
        <div className="mb-15">
          <Row>
            <Col span={24}>
              <strong className="text-md skybase-dashboard__title">
                Manage Batal Kasir Pasien
              </strong>
            </Col>
          </Row>
        </div>
        <Collapse
          defaultActiveKey={['1']}
          expandIconPosition="right"
          className="mb-15">
          <Panel
            header={
              <strong className="text-md skybase-dashboard__title">
                Filter
              </strong>
            }
            key="1">
            <div>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Transaction Date Range
                  <SelectDropdown
                    options={listDateRange}
                    placeHolder="Select Transaction Date Range"
                    allowClear
                    value={rangeDate}
                    onChange={handleRangeDateChange}
                  />
                </Col>
                <Col span={8}>
                  Start Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        placeholder="Start Date"
                        disabled={rangeDate !== 'CUSTOM'}
                        value={startDate}
                        onChange={handleStartDateChange}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  End Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        placeholder="End Date"
                        disabled={rangeDate !== 'CUSTOM'}
                        value={endDate}
                        onChange={handleEndDateChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Void & Retur Date Range
                  <SelectDropdown
                    options={listDateRange}
                    placeHolder="Select Void & Retur Date Range"
                    value={rangeDateVoid}
                    allowClear
                    onChange={handleRangeDateChangeVoid}
                  />
                </Col>
                <Col span={8}>
                  Start Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        value={startDateVoid}
                        placeholder="Start Date"
                        disabled={rangeDateVoid !== 'CUSTOM'}
                        onChange={handleStartDateChangeVoid}
                      />
                    </div>
                  </div>
                </Col>
                <Col span={8}>
                  End Date
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <DatePicker
                        showToday
                        allowClear={false}
                        format="DD/MM/YYYY"
                        value={endDateVoid}
                        placeholder="End Date"
                        disabled={rangeDateVoid !== 'CUSTOM'}
                        onChange={handleEndDateChangeVoid}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col span={8}>
                  Voided & Returned by
                  <div className="mb-15">
                    <div className="skybase-dashboard__search">
                      <SelectDropdown
                        options={listCashierOptions}
                        placeHolder="Select voided & returned by"
                        allowClear
                        onChange={handleCashierChange}
                        value={cashier}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              <Row className="mt-20 mb-10">
                <Col span={24} type="flex" align="end">
                  <Row gutter={[16, 8]}>
                    <Button onClick={resetAllFields}>Reset</Button>
                    <Button
                      type="primary"
                      style={{ margin: '0 10px' }}
                      onClick={() => getReceiptList(10, 1)}>
                      Filter
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
        <div className="panel">
          <Card
            title="MANAGE BATAL KASIR PASIEN"
            headStyle={{ fontSize: '20px' }}
            style={{ border: '0px', backgroundColor: 'transparent' }}>
            <Row>
              <Col span={2} type="flex" align="start">
                <div className="mb-15">
                  <Dropdown
                    trigger={'click'}
                    overlay={() => (
                      <>
                        <Menu>
                          <Menu.Item>
                            <Button
                              type="link"
                              onClick={() => generateExcelReport()}>
                              Excel Report Batal Kasir Pasien
                            </Button>
                          </Menu.Item>
                        </Menu>
                      </>
                    )}>
                    <Button>
                      <Icon type="download" />
                      Export File
                      <Icon type="down" />
                    </Button>
                  </Dropdown>
                </div>
              </Col>
              <Col span={5} offset={17} type="flex" align="end">
                <div className="mb-15">
                  <Search
                    className="skybase-dashboard__search-input"
                    placeholder="Search"
                    onChange={onSearchChange}
                    value={search}
                  />
                </div>
              </Col>
            </Row>
            <Table
              dataSource={listFilteredReceipt}
              rowKey="key"
              columns={cashierReceiptVoidColumns(
                handlePrintReceiptClick,
                pagination.current
              )}
              onChange={onTableChange}
              className="skybase-dashboard__active-order-table"
              footer={() => (
                <Table
                  dataSource={listSummaryReceipt}
                  rowKey="key"
                  columns={discountSummaryColumns}
                  className="skybase-dashboard__active-order-table"
                  showHeader={false}
                  pagination={false}
                  size="small"
                />
              )}
              pagination={pagination}
              size="small"
              scroll={{ x: 2000, y: 500 }}
            />
          </Card>
        </div>
      </Content>
    </Layout>
  );
}

export default withRouter(ManageBatalKasirPasien);
